// @flow

import LotPrices, { type LotObject } from './LotPrices'
import Parking, { type ParkingObject } from './Parking'

export default class Floor {
  +id: string
  +label: string
  +order: number
  +lots: ?(LotPrices[])
  +parkings: ?(Parking[])

  constructor(
    id: string,
    label: string,
    order: number,
    lots: ?(LotPrices[]),
    parkings: ?(Parking[]),
  ) {
    this.id = id
    this.label = label
    this.order = order
    this.lots = lots
    this.parkings = parkings

    Object.freeze(this)
  }

  updateLots(lot: LotObject) {
    if (!this.lots) {
      throw new Error('No lot instantiated')
    }
    this.lots.forEach(oneLot => oneLot.updateLot(lot))
  }

  updateParkings(parking: ParkingObject) {
    if (!this.parkings) {
      throw new Error('No parking instantiated')
    }
    this.parkings.forEach(oneParking => oneParking.updateParking(parking))
  }

  updateParkingsLotAssociation({
    lotId,
    parkingId,
    label,
    status,
  }: {
    lotId: string,
    parkingId: string,
    label?: string,
    status?: string,
  }) {
    if (!this.parkings) {
      throw new Error('No parking instantiated')
    }
    if (label && status) {
      this.parkings.forEach(oneParking =>
        oneParking.addLotAssociation({ lotId, parkingId, label, status }),
      )
    } else {
      this.parkings.forEach(oneParking =>
        oneParking.removeLotAssociation({ lotId, parkingId }),
      )
    }
  }

  is(lookedId: string): boolean {
    return this.id === lookedId
  }
}
