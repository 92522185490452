// @flow

import axios, { Axios, type $AxiosError, type AxiosXHRConfig } from 'axios'
import { action, observable, computed, runInAction } from 'mobx'
import ClientOAuth2, { Token } from 'client-oauth2'
import { RootStore } from '../RootStore'
import routes from '../Routes'
import { TokenStorage } from './../Infra/TokenStorage'
import i18n from '../i18n'

export default class AuthenticationStore {
  +rootStore: RootStore
  +authClient: typeof ClientOAuth2
  +tokenStorage: TokenStorage

  +httpClient: Axios

  @observable token: ?typeof Token = null
  @observable me: ?{
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    locale: string,
  } = null

  constructor(
    rootStore: RootStore,
    authClient: typeof ClientOAuth2,
    tokenStorage: TokenStorage,
    apiEndpoint: string,
  ) {
    this.rootStore = rootStore
    this.authClient = authClient
    this.tokenStorage = tokenStorage

    this.httpClient = axios.create({ baseURL: apiEndpoint })
    this.httpClient.interceptors.request.use(this._signRequest.bind(this))
    this.httpClient.interceptors.response.use(
      null,
      this._checkAuthenticationError.bind(this),
    )
  }

  @action async onProfileEdition(
    user: ?{ firstName: string, lastName: string, email: string },
    password: ?{ oldPassword: string, newPassword: string },
  ) {
    if (!this.me) {
      throw new Error('No user instantiated')
    }

    const response = { success: true, errors: [] }

    if (user) {
      try {
        await this.httpClient.put(`/api/seller/${this.me.id}`, user)
      } catch (err) {
        if (err.response && err.response.status === 400) {
          response.success = false
          response.errors = [
            ...response.errors,
            ...err.response.data.map(oneError => ({
              field: oneError.source,
              message: oneError.message,
            })),
          ]
        }
      }
    }

    if (password) {
      try {
        await this.httpClient.put(
          `/api/seller/password/${this.me.id}`,
          password,
        )
      } catch (err) {
        if (err.response && err.response.status === 400) {
          response.success = false
          response.errors = [
            ...response.errors,
            ...err.response.data.map(oneError => ({
              field: oneError.source,
              message: oneError.message,
            })),
          ]
        }
      }
    }

    runInAction(() => {
      if (response.success && this.me && user) {
        this.me.firstName = user.firstName
        this.me.lastName = user.lastName
        this.me.email = user.email
      }
    })

    return response
  }

  @action async fetchMe() {
    if (this.me) {
      return
    }
    const response = await this.httpClient.get('/api/seller/me')

    if (response.data.locale && i18n.language !== response.data.locale) {
      i18n.changeLanguage(response.data.locale)
    }

    runInAction(() => {
      this.me = response.data
    })
  }

  @computed
  get userInitial(): string {
    const me = this.me
    return me
      ? (me.firstName ? me.firstName.charAt(0) : '') +
          (me.lastName ? me.lastName.charAt(0) : '')
      : ''
  }

  @computed
  get loggedIn(): boolean {
    return !!this.token
  }

  @action
  loadTokenFromStorage() {
    this.token = this.tokenStorage.read()
  }

  @action
  async performLogin(login: string, password: string, rememberMe: boolean) {
    let token
    try {
      token = await this.authClient.owner.getToken(login, password)
    } catch (err) {
      if (err.code !== 'EAUTH') {
        throw err
      }

      return {
        success: false,
        errors:
          err.body && err.body.error_description
            ? [{ field: null, message: err.body.error_description }]
            : [{ field: null, message: err.toString() }],
      }
    }

    if (!token) {
      throw new Error('No login error detected but no token available neither')
    }

    runInAction(() => {
      this.token = token
      rememberMe && this.tokenStorage.write(this.token)

      this.rootStore.router.goTo(routes.program_selection_page)
    })

    return {
      success: true,
      errors: [],
    }
  }

  @action
  async requestNewPassword(email: string) {
    try {
      await this.httpClient.post('/api/password/request', {
        email: email,
      })
    } catch (err) {
      if (err.response && err.response.status === 400) {
        return {
          success: false,
          errors: err.response.data.map(oneError => ({
            field: oneError.source,
            message: oneError.message,
          })),
        }
      }

      throw err
    }

    return {
      success: true,
      errors: [],
    }
  }

  @action
  async resetPassword(token: string, newPassword: string) {
    try {
      const response = await this.httpClient.post('/api/password/reset', {
        token: token,
        newPassword: newPassword,
      })

      if (response.data && response.data.email) {
        this.performLogin(response.data.email, newPassword, true)
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        return {
          success: false,
          errors: err.response.data.map(oneError => ({
            field: oneError.source,
            message: oneError.message,
          })),
        }
      }

      throw err
    }

    return {
      success: true,
      errors: [],
    }
  }

  @action
  performLogout() {
    this.token = null
    this.me = null
    this.tokenStorage.delete()
    this.rootStore.router.goTo(routes.login_page)
  }

  _signRequest<T>(config: AxiosXHRConfig<T>): AxiosXHRConfig<T> {
    const token = this.token
    if (token) {
      if (token.expired()) {
        this.performLogout()
        throw new Error('Token expired: request canceled')
      }

      return token.sign(config)
    }

    return config
  }

  _checkAuthenticationError<T>(error: $AxiosError<T>): any {
    const { response } = error
    if (!response || response.status !== 401) {
      return Promise.reject(error)
    }

    this.performLogout()
  }
}
