// @flow

import FilterConstraint from './FilterConstraint'

export type FilterValueComparator = (value: any, value: any) => boolean
export type FilterValueMatcher<T> = (lot: T, value: any) => boolean

export default class FilterDefinition<T> {
  +id: string
  +label: string
  +constraints: FilterConstraint[]
  +comparator: FilterValueComparator
  +matcher: FilterValueMatcher<T>

  constructor(
    id: string,
    label: string,
    constraints: FilterConstraint[],
    comparator: FilterValueComparator,
    matcher: FilterValueMatcher<T>,
  ) {
    this.id = id
    this.label = label
    this.constraints = constraints
    this.comparator = comparator
    this.matcher = matcher

    Object.freeze(this)
  }

  match(lot: T, value: string): boolean {
    return this.matcher(lot, value)
  }
}
