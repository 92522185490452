// @flow

import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../Colors'
import { RobotoBold } from '../../Typography/Fonts'
import AddCardSvg from '../../icons/AddCardSvg'
import ArrowUpSvg from '../../icons/ArrowUpSvg'
import SelectVat from '../SelectVat'
import { getStatusColor } from '../Helper'

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 296px;
  margin: 0 4px;
  padding: ${({ isOver, canDrop }) => (isOver && canDrop ? '40px 8px' : '0px')};
  transition: padding 0.25s ease-in-out;
`

const ColumnWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: ${Colors.carbon};
  padding-top: 39px;
  padding-bottom: 1px;
`

const ColumnCard = styled.div`
  overflow: auto;
  height: 100%;
`

const ColumnHeader = styled.div`
  position: absolute;
  width: 276px;
  top: 11px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 18px;
  height: 18px;
  color: ${Colors.white50};
  span:first-of-type {
    margin-left: 4px;
  }
`

const AddCardWrapper = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  left: 50%;
  margin-left: -7px;
  top: 50%;
  margin-top: -7px;
  opacity: ${({ canDrop }) => (canDrop ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
`

const RightContainer = styled.div`
  height: 18px;
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  transition: opacity 0.25s ease-in-out;
`

const ArrowUpWrapper = styled.div`
  cursor: pointer;
  path {
    transition: fill-opacity 0.25s ease-in-out;
  }
  &:hover path {
    fill-opacity: 1;
  }
`

type Props = {|
  children: React.Node,
  collapsed: boolean,
  setCollapse: () => void,
  collapsable: boolean,
  columnStatus: string,
  count: number,
  subCount: number,
  canDrop: boolean,
  isOver: boolean,
  isDragging: boolean,
  displayedVat: number,
  setDisplayedVat: () => void,
  vatList: number[],
|}

const ColumnCardComponent = ({
  children,
  collapsed,
  setCollapse,
  collapsable,
  columnStatus,
  count,
  subCount,
  canDrop,
  isOver,
  isDragging,
  displayedVat,
  setDisplayedVat,
  vatList,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Container canDrop={canDrop} isOver={isOver}>
      <AddCardWrapper canDrop={canDrop}>
        <AddCardSvg />
      </AddCardWrapper>
      <ColumnHeader>
        <Label>
          {t(`status.${columnStatus}`)}

          {count !== subCount ? (
            <React.Fragment>
              <span style={{ color: getStatusColor(columnStatus) }}>
                {subCount}
              </span>
              <span style={{ color: Colors.white20, marginLeft: '1px' }}>
                /{count}
              </span>
            </React.Fragment>
          ) : (
            <span style={{ color: getStatusColor(columnStatus) }}>{count}</span>
          )}
        </Label>

        {collapsable && (
          <RightContainer isDragging={isDragging}>
            {collapsed ? (
              <ArrowUpWrapper onClick={() => setCollapse()}>
                <ArrowUpSvg />
              </ArrowUpWrapper>
            ) : (
              <SelectVat
                vatList={vatList}
                onVatClick={setDisplayedVat}
                value={displayedVat}
              />
            )}
          </RightContainer>
        )}
      </ColumnHeader>

      <ColumnWrapper>
        <ColumnCard
          style={{
            transition: 'opacity 0.15s ease-in-out',
            opacity: isDragging ? 0 : 1,
            pointerEvents: isDragging ? 'none' : 'all',
          }}
        >
          {children}
        </ColumnCard>
      </ColumnWrapper>
    </Container>
  )
}

export default React.memo<Props>(ColumnCardComponent)
