// @flow

export default class Configuration {
  +configurationId: string
  +configurationOptionPrice: number
  +receiptUrl: string

  constructor(
    configurationId: string,
    configurationOptionPrice: number,
    receiptUrl: string,
  ) {
    this.configurationId = configurationId
    this.configurationOptionPrice = configurationOptionPrice
    this.receiptUrl = receiptUrl

    Object.freeze(this)
  }
}
