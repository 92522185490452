// @flow

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { VerticalDashedSeparator } from '../../DashedSeparator'
import { Colors } from '../../Colors'
import { RobotoMedium, RobotoBold } from '../../Typography/Fonts'
import SwitchInput from '../Form/SwitchInput'
import CustomiseSvg from '../../icons/CustomiseSvg'
import OnlineSvg from '../../icons/OnlineSvg'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: calc(100% - 56px);
`

const Section = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  svg {
    margin-bottom: 8px;
  }
`

const Separator = styled(VerticalDashedSeparator)`
  height: 115px;
`

const Title = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 24px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 25px;
  color: ${Colors.white50};
  margin-bottom: 8px;
`

const Description = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 16px;
  color: ${Colors.white50};
  margin-bottom: 8px;
`

const Website = styled.a`
  display: block;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 18px;
  color: ${Colors.blue};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

type Props = {
  disableModification: boolean,
  website: string,
  onOptionsModificationChange: () => Promise<void>,
}

const ProgramSettings = ({
  disableModification,
  website,
  onOptionsModificationChange,
}: Props) => {
  const { t } = useTranslation()

  const [disableOptionsModification, setOptionsModification] = React.useState(
    disableModification,
  )

  const onSwitchChange = () => {
    setOptionsModification(!disableOptionsModification)
    onOptionsModificationChange()
  }

  return (
    <Wrapper>
      <Section>
        <CustomiseSvg />
        <Title>{t('programEdition.customisation')}</Title>
        <Description>{t('programEdition.allowModifications')}</Description>
        <SwitchInput
          leftLabel={t('programEdition.granted')}
          rightLabel={t('programEdition.deny')}
          onChange={onSwitchChange}
          toggled={disableOptionsModification}
          color={Colors.blue}
        />
      </Section>
      <Separator />
      <Section>
        <OnlineSvg />
        <Title>{t('programEdition.online')}</Title>
        <Description>{t('programEdition.visitWebsite')}</Description>
        <Website href={website} target="_blank">
          {website}
        </Website>
      </Section>
    </Wrapper>
  )
}

export default React.memo<Props>(ProgramSettings)
