// @flow

export default class FilterConstraint {
  +value: any
  +label: string

  constructor(value: any, label: string) {
    this.value = value
    this.label = label

    Object.freeze(this)
  }
}
