// @flow
import { /* computed, */ observable } from 'mobx'

export type ParkingObject = {|
  id: string,
  label: string,
  status: string,
  priceExcludingVAT: number,
  estateLinked: ?{ lotId: string, label: string, status: string },
|}

export default class Parking {
  +id: string
  +label: string
  +available: boolean

  @observable status: string = 'available'
  @observable priceExcludingVAT: number = 0
  @observable estateLinked: ?{
    lotId: string,
    label: string,
    status: string,
  } = null

  constructor(
    id: string,
    label: string,
    status: string,
    priceExcludingVAT: number,
    estateLinked: ?{ lotId: string, label: string, status: string },
  ) {
    this.id = id
    this.label = label
    this.status = status
    this.priceExcludingVAT = priceExcludingVAT
    this.estateLinked = estateLinked

    Object.freeze(this)
  }

  updateParking(parking: ParkingObject) {
    if (this.id === parking.id) {
      this.priceExcludingVAT = parking.priceExcludingVAT
      this.status = parking.status
    }
  }

  addLotAssociation({
    lotId,
    parkingId,
    label,
    status,
  }: {
    lotId: string,
    parkingId: string,
    label: string,
    status: string,
  }) {
    if (this.id === parkingId) {
      this.estateLinked = { lotId, label, status }
    }
  }

  removeLotAssociation({
    lotId,
    parkingId,
  }: {
    lotId: string,
    parkingId: string,
  }) {
    if (this.id === parkingId) {
      this.estateLinked = null
      this.status = 'available'
    }
  }

  is(lookedId: string): boolean {
    return this.id === lookedId
  }
}
