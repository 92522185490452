// @flow

import FilterSet from './Filter/FilterSet'
import FilterDefinition from './Filter/FilterDefinition'
import FilterConstraint from './Filter/FilterConstraint'
import Lot from './Lot'
import Program from './Program'
import i18n from '../i18n'

const deduplicateValue = <T>(source: Array<T>): Array<T> =>
  source.filter((item, index) => source.indexOf(item) === index)

const deduplicateFloor = (
  source: { floorOrder: number, floorLabel: string }[],
): { floorOrder: number, floorLabel: string }[] => {
  const arr = []
  source.map(floor => {
    if (
      !arr.some(oneArrayItem => oneArrayItem.floorOrder === floor.floorOrder)
    ) {
      arr.push(floor)
    }

    return arr
  })
  return arr
}

export const buildTypologyFilterDefinition = (
  sourceLots: Lot[],
): FilterDefinition<Lot> => {
  const availableTypologies = deduplicateValue(
    sourceLots.map(oneLot => oneLot.typology),
  ).sort()

  return new FilterDefinition(
    'typology',
    i18n.t('lot.typology'),
    availableTypologies.map(
      oneTypology => new FilterConstraint(oneTypology, oneTypology),
    ),
    (a: string, b: string) => a === b,
    (lot: Lot, value: string) => lot.typology === value,
  )
}

export const buildSurfaceFilterDefinition = (
  sourceLots: Lot[],
): FilterDefinition<Lot> => {
  const possibleSurfaceIntervals = [
    { interval: { from: 0, to: 30 }, label: '< 30m²' },
    { interval: { from: 30, to: 40 }, label: '30-40m²' },
    { interval: { from: 40, to: 50 }, label: '40-50m²' },
    { interval: { from: 50, to: 60 }, label: '50-60m²' },
    { interval: { from: 60, to: 70 }, label: '60-70m²' },
    { interval: { from: 70, to: 80 }, label: '70-80m²' },
    { interval: { from: 80, to: 90 }, label: '80-90m²' },
    { interval: { from: 80, to: Infinity }, label: '> 90m²' },
  ]

  const availableAreas = sourceLots.map(oneLot => oneLot.area).sort()

  const availableSurfaceIntervals = possibleSurfaceIntervals.filter(
    onePossibleSurfaceInterval =>
      availableAreas.some(
        availableArea =>
          availableArea >= onePossibleSurfaceInterval.interval.from &&
          availableArea <= onePossibleSurfaceInterval.interval.to,
      ),
  )

  return new FilterDefinition(
    'area',
    i18n.t('lot.area'),
    availableSurfaceIntervals.map(
      oneAvailableSurfaceIntervals =>
        new FilterConstraint(
          oneAvailableSurfaceIntervals.interval,
          oneAvailableSurfaceIntervals.label,
        ),
    ),

    (a: {| from: number, to: number |}, b: {| from: number, to: number |}) =>
      a.from === b.from && a.to === b.to,
    (lot: Lot, value: {| from: number, to: number |}) =>
      lot.area >= value.from && lot.area <= value.to,
  )
}

export const buildOrientationFilterDefinition = (
  sourceLots: Lot[],
): FilterDefinition<Lot> => {
  const sourceOrientations = deduplicateValue(
    sourceLots.map(oneLot => oneLot.orientation),
  )

  const availableOrientations = [
    'N',
    'NE',
    'E',
    'SE',
    'S',
    'SW',
    'W',
    'NW',
    'X',
  ].filter(onePossibleOrientation =>
    sourceOrientations.includes(onePossibleOrientation),
  )

  return new FilterDefinition(
    'orientation',
    i18n.t('lot.orientation'),
    availableOrientations.map(
      oneAvailableOrientation =>
        new FilterConstraint(
          oneAvailableOrientation,
          i18n.t(`orientation.${oneAvailableOrientation}.longue`),
        ),
    ),
    (a: string, b: string) => a === b,
    (lot: Lot, value: string) => lot.orientation === value,
  )
}

export const buildFloorFilterDefinition = (
  sourceLots: Lot[],
): FilterDefinition<Lot> => {
  const availableFloors = deduplicateFloor(
    sourceLots.map(oneLot => {
      return {
        floorOrder: oneLot.floorOrder,
        floorLabel: oneLot.floorLabel,
      }
    }),
  ).sort((a, b) => a.floorOrder - b.floorOrder)

  return new FilterDefinition(
    'floor',
    i18n.t('lot.floor'),
    availableFloors.map(
      oneAvailableFloor =>
        new FilterConstraint(
          oneAvailableFloor.floorOrder,
          oneAvailableFloor.floorLabel,
        ),
    ),
    (a: number, b: number) => a === b,
    (lot: Lot, value: string) => lot.floorOrder === value,
  )
}

export const buildOptionFilterDefinition = (
  sourceLots: Lot[],
): ?FilterDefinition<Lot> => {
  const atLeastOneLotHaveABalcony = sourceLots.some(
    oneLot => !!oneLot.balconyArea,
  )
  const atLeastOneLotHaveAGarden = sourceLots.some(
    oneLot => !!oneLot.gardenArea,
  )

  const atLeastOneLotIsDuplex = sourceLots.some(oneLot => !!oneLot.duplex)

  if (
    !atLeastOneLotHaveABalcony &&
    !atLeastOneLotHaveAGarden &&
    !atLeastOneLotIsDuplex
  ) {
    return null
  }

  return new FilterDefinition(
    'option',
    i18n.t('lot.option'),
    [
      ...(atLeastOneLotHaveABalcony
        ? [new FilterConstraint('balconyArea', i18n.t('lot.balcony'))]
        : []),
      ...(atLeastOneLotHaveAGarden
        ? [new FilterConstraint('gardenArea', i18n.t('lot.garden'))]
        : []),
      ...(atLeastOneLotIsDuplex
        ? [new FilterConstraint('duplex', i18n.t('lot.duplex'))]
        : []),
    ],
    (a: string, b: string) => a === b,
    (lot: Lot, value: string) => {
      if (value === 'balconyArea') {
        return !!lot.balconyArea
      }

      if (value === 'gardenArea') {
        return !!lot.gardenArea
      }

      if (value === 'duplex') {
        return !!lot.duplex
      }

      throw new Error("Unable to compare the lot to it's value")
    },
  )
}

export const buildBuildingFilterDefinition = (
  sourceLots: Lot[],
  sourceProgram: Program,
): ?FilterDefinition<Lot> => {
  const sourceBuildingIds = deduplicateValue(
    sourceLots.map(oneLot => oneLot.buildingId),
  )

  const availableBuildings = sourceProgram
    .getBuildings()
    .filter(oneAvailableBuilding =>
      sourceBuildingIds.includes(oneAvailableBuilding.id),
    )

  if (availableBuildings.length <= 1) {
    return null
  }

  return new FilterDefinition(
    'building',
    i18n.t('lot.building'),
    availableBuildings.map(
      oneAvailableBuilding =>
        new FilterConstraint(
          oneAvailableBuilding.id,
          oneAvailableBuilding.label,
        ),
    ),
    (a: string, b: string) => a === b,
    (lot: Lot, value: string) => lot.buildingId === value,
  )
}

export const lotFilterSetBuilder = (
  sourceLots: Lot[],
  sourceProgram: Program,
): FilterSet<Lot> => {
  return FilterSet.fromFilterDefinitions(
    [
      buildBuildingFilterDefinition(sourceLots, sourceProgram),
      buildFloorFilterDefinition(sourceLots),
      buildTypologyFilterDefinition(sourceLots),
      buildSurfaceFilterDefinition(sourceLots),
      buildOrientationFilterDefinition(sourceLots),
      buildOptionFilterDefinition(sourceLots),
    ].filter(Boolean),
  )
}
