// @flow

import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../StoreContext'
import ApplicationLayout from '../../ApplicationLayout'
import EstatesEdition from '../Guidelines/components/Program/EstatesEdition'
import { ProgramHeader } from '../Guidelines/components/PageHeader'

const ProgramPricesPage = observer(() => {
  const { programPricesStore, router } = React.useContext(StoreContext)

  const [importSucceed, setImportSucceed] = React.useState(null)
  const [importError, setImportError] = React.useState(null)
  const program = programPricesStore.program

  if (!program) {
    return <ApplicationLayout />
  }

  const requestDownloadXls = async () => {
    const data = await programPricesStore.exportLots()

    const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.style.display = 'none'

    document.body && document.body.appendChild(a)
    a.setAttribute('href', url)
    a.setAttribute('download', `${program.label}-lots.xls`)
    a.click()

    document.body && document.body.removeChild(a)
  }

  const onImportLots = async (file: string) => {
    const reader = new window.FileReader()
    reader.readAsDataURL(file)

    reader.onload = async data => {
      const { success, error } = await programPricesStore.importLots(
        reader.result.split(',')[1],
      )

      if (!success) {
        setImportSucceed(false)
        setImportError(error)
      } else {
        setImportSucceed(true)
      }
    }
  }

  const closeFeedback = () => {
    setImportSucceed(null)
    setImportError(null)
  }

  return (
    <ApplicationLayout>
      <ProgramHeader
        programLabel={program.label}
        programTotalNumberOfLots={program.totalNumberOfLots}
        programTotalNumberOfParkings={program.totalNumberOfParkings}
        programSoldLot={program.lotsSalesStatus.sold}
        goTo={route =>
          router.goTo(route, {
            programId: program.id,
          })
        }
        currentPath={router.currentRoute.originalPath}
      />

      <EstatesEdition
        buildings={programPricesStore.buildingsWithoutFloors}
        currentBuilding={programPricesStore.currentBuildingOrdered}
        onSelectBuilding={buildingId =>
          programPricesStore.setCurrentBuilding(buildingId)
        }
        vatList={program.vatAvailable}
        onSave={() => programPricesStore.saveNewLotPrices()}
        lotsUpdates={programPricesStore.updatedlots}
        onLotPriceEdition={(lot, value) =>
          programPricesStore.onLotPriceEdition(lot, value)
        }
        onLotVatEdition={(lot, value) =>
          programPricesStore.onLotVatEdition(lot, value)
        }
        onLotPriceHideEdition={(lot, value) =>
          programPricesStore.onLotPriceHideEdition(lot, value)
        }
        onImportLots={file => onImportLots(file)}
        onExportLots={() => requestDownloadXls()}
        closeFeedback={closeFeedback}
        importSucceed={importSucceed}
        importError={importError}
      />
    </ApplicationLayout>
  )
})

export default ProgramPricesPage
