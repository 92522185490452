// @flow

import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../StoreContext'
import ApplicationLayout from '../../ApplicationLayout'
import ParkingsEdition from '../Guidelines/components/Program/ParkingsEdition'
import { ProgramHeader } from '../Guidelines/components/PageHeader'

const ProgramParkingsPage = observer(() => {
  const { programParkingsStore, router } = React.useContext(StoreContext)

  const [importSucceed, setImportSucceed] = React.useState(null)
  const [importError, setImportError] = React.useState(null)
  const program = programParkingsStore.program

  if (!program) {
    return <ApplicationLayout />
  }

  const requestDownloadXls = async () => {
    const data = await programParkingsStore.exportParkings()

    const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.style.display = 'none'

    document.body && document.body.appendChild(a)
    a.setAttribute('href', url)
    a.setAttribute('download', `${program.label}-parkings.xls`)
    a.click()

    document.body && document.body.removeChild(a)
  }

  const onImportParkings = async (file: string) => {
    const reader = new window.FileReader()
    reader.readAsDataURL(file)

    reader.onload = async data => {
      const { success, error } = await programParkingsStore.importParkings(
        reader.result.split(',')[1],
      )

      if (!success) {
        setImportSucceed(false)
        setImportError(error)
      } else {
        setImportSucceed(true)
      }
    }
  }

  const closeFeedback = () => {
    setImportSucceed(null)
    setImportError(null)
  }

  return (
    <ApplicationLayout>
      <ProgramHeader
        programLabel={program.label}
        programTotalNumberOfLots={program.totalNumberOfLots}
        programTotalNumberOfParkings={program.totalNumberOfParkings}
        programSoldLot={program.lotsSalesStatus.sold}
        goTo={route =>
          router.goTo(route, {
            programId: program.id,
          })
        }
        currentPath={router.currentRoute.originalPath}
      />

      <ParkingsEdition
        buildings={programParkingsStore.buildingsWithoutFloors}
        currentBuilding={programParkingsStore.currentBuildingOrdered}
        onSelectBuilding={buildingId =>
          programParkingsStore.setCurrentBuilding(buildingId)
        }
        vatList={program.vatAvailable}
        saveParkings={() => programParkingsStore.saveParkings()}
        parkingsUpdates={programParkingsStore.updatedParkings}
        onParkingPriceEdition={(parking, value) =>
          programParkingsStore.onParkingPriceEdition(parking, value)
        }
        onParkingStatusEdition={(parking, status) =>
          programParkingsStore.onParkingStatusEdition(parking, status)
        }
        onAddLot={(lotId, parkingId, label, status, parking) =>
          programParkingsStore.addLot(lotId, parkingId, label, status, parking)
        }
        onRemoveLot={(lotId, parkingId) =>
          programParkingsStore.removeLot(lotId, parkingId)
        }
        lotsForAssociation={programParkingsStore.lotsForAssociation}
        onImportParkings={file => onImportParkings(file)}
        onExportParkings={() => requestDownloadXls()}
        closeFeedback={closeFeedback}
        importSucceed={importSucceed}
        importError={importError}
      />
    </ApplicationLayout>
  )
})

export default ProgramParkingsPage
