// @flow

import ClientOAuth2 from 'client-oauth2'
import url from 'url'
import { RouterStore } from 'mobx-router'
import AuthenticationStore from './Stores/AuthenticationStore'
import ProgramsStore from './Stores/ProgramsStore'
import LotsStore from './Stores/LotsStore'
import CookieTokenStorage from './Infra/CookieTokenStorage'
import ProgramPricesStore from './Stores/ProgramPricesStore'
import ProgramParkingsStore from './Stores/ProgramParkingsStore'
import ProgramSettingsStore from './Stores/ProgramSettingsStore'

export class RootStore {
  +apiEndpoint: string
  +authenticationStore: AuthenticationStore
  +programsStore: ProgramsStore
  +lotsStore: LotsStore
  +programPricesStore: ProgramPricesStore
  +programParkingsStore: ProgramParkingsStore
  +programSettingsStore: ProgramSettingsStore
  +router: typeof RouterStore

  constructor() {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    if (!API_ENDPOINT) {
      throw new Error(
        'The environment variable "REACT_APP_API_ENDPOINT" is missing or empty',
      )
    }

    const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID
    if (!OAUTH_CLIENT_ID) {
      throw new Error(
        'The environment variable "REACT_APP_OAUTH_CLIENT_ID" is missing or empty',
      )
    }

    const OAUTH_CLIENT_SECRET = process.env.REACT_APP_OAUTH_CLIENT_SECRET
    if (!OAUTH_CLIENT_SECRET) {
      throw new Error(
        'The environment variable "REACT_APP_OAUTH_CLIENT_SECRET" is missing or empty',
      )
    }

    // FIXME: As we use core-js v3, check this code and comment
    // The browser version of the `url` module implement the deprecated version of the `url` API (thanks core-js v2)
    const accessTokenUri = url.format({
      ...url.parse(API_ENDPOINT || '/'), // eslint-disable-line node/no-deprecated-api
      ...{ pathname: '/oauth/v2/token' },
    })

    const authClient = new ClientOAuth2({
      clientId: OAUTH_CLIENT_ID,
      clientSecret: OAUTH_CLIENT_SECRET,
      accessTokenUri,
      scopes: [],
    })

    const cookieTokenStorage = new CookieTokenStorage(
      authClient,
      document.location.protocol,
      process.env.REACT_APP_AUTH_COOKIE_NAME || undefined,
    )

    this.apiEndpoint = API_ENDPOINT

    this.router = new RouterStore(this)
    this.authenticationStore = new AuthenticationStore(
      this,
      authClient,
      cookieTokenStorage,
      API_ENDPOINT,
    )
    this.programsStore = new ProgramsStore(this)
    this.lotsStore = new LotsStore(this)
    this.programPricesStore = new ProgramPricesStore(this)
    this.programParkingsStore = new ProgramParkingsStore(this)
    this.programSettingsStore = new ProgramSettingsStore(this)
  }
}

const store = new RootStore()

export default store
