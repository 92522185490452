// @flow

import * as React from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { TextInputField } from '../Form/InputField'
import { ErrorMessage } from '../Form/index'
import { ButtonBlue } from '../Button'
import { RobotoMedium } from '../../Typography/Fonts'
import { Colors } from '../../Colors'

const Wrapper = styled.div`
  width: 100%;
`

const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
`

const Description = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 16px;
  color: ${Colors.white50};
  margin-top: 20px;
`

type Props = {|
  onApplyNewPassword: (
    newPassword: string,
  ) => Promise<{
    success: boolean,
    errors: { field: ?string, message: string }[],
  }>,
  t: string => string,
|}

type State = {|
  newPassword: string,
  confirmNewPassword: string,
  errors: { field: ?string, message: string }[],
  applyNewPasswordInProgress: boolean,
  confirmPasswordError: boolean,
|}

class NewPasswordForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      newPassword: '',
      confirmNewPassword: '',
      errors: [],
      applyNewPasswordInProgress: false,
      confirmPasswordError: false,
    }
  }

  handleNewPasswordFormSubmit = async () => {
    const { onApplyNewPassword } = this.props
    const { newPassword, confirmNewPassword } = this.state

    if (newPassword !== confirmNewPassword) {
      this.setState({
        confirmPasswordError: true,
      })
      return
    }

    this.setState(
      {
        applyNewPasswordInProgress: true,
        errors: [],
        confirmPasswordError: false,
      },
      async () => {
        const { success, errors } = await onApplyNewPassword(newPassword)

        if (!success) {
          this.setState({ errors, applyNewPasswordInProgress: false })
        }
      },
    )
  }

  render() {
    const {
      newPassword,
      confirmNewPassword,
      errors,
      applyNewPasswordInProgress,
      confirmPasswordError,
    } = this.state
    const { t } = this.props

    return (
      <Wrapper>
        <form
          onSubmit={e => {
            e.preventDefault()
            this.handleNewPasswordFormSubmit()
          }}
        >
          <TextInputField
            label={t('login.newPassword')}
            type="password"
            value={newPassword}
            onChange={e => {
              this.setState({ newPassword: e.target.value })
            }}
            errorMessage={
              confirmPasswordError ? t('login.passwordError') : null
            }
            autoComplete="new-password"
          />

          <TextInputField
            label={t('login.newPasswordConfirmation')}
            type="password"
            value={confirmNewPassword}
            onChange={e => {
              this.setState({ confirmNewPassword: e.target.value })
            }}
            autoComplete="new-password"
          />

          {errors.map(({ message }) => (
            <ErrorMessage key={message}>{message}</ErrorMessage>
          ))}

          <Description>{t('login.newPasswordDescription')}</Description>

          <ButtonContainer>
            <ButtonBlue disabled={applyNewPasswordInProgress} type="submit">
              {t('login.apply')}
            </ButtonBlue>
          </ButtonContainer>
        </form>
      </Wrapper>
    )
  }
}

export default withTranslation()(NewPasswordForm)
