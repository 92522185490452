// @flow

import Cookie from 'js-cookie'
import ClientOAuth2, { Token } from 'client-oauth2'
import { TokenStorage } from './TokenStorage'

type HTTP_PROTOCOL = 'http:' | 'https:' | string

const shouldUseSecureToken = (protocol: HTTP_PROTOCOL) => {
  return protocol === 'https:'
}

export default class CookieTokenStorage implements TokenStorage {
  +authClient: typeof ClientOAuth2
  +isSecureTransaction: boolean
  +cookieName: string

  constructor(
    authClient: typeof ClientOAuth2,
    protocol: HTTP_PROTOCOL = 'https:',
    cookieName: string = 'auth_token',
  ) {
    this.authClient = authClient
    this.isSecureTransaction = shouldUseSecureToken(protocol)
    this.cookieName = cookieName
  }

  read(): ?typeof Token {
    const authCookie = Cookie.get(this.cookieName)
    if (!authCookie) {
      return null
    }

    const { expire_at: expireAt, ...rawToken } = JSON.parse(authCookie)

    const token = this.authClient.createToken(
      rawToken.access,
      rawToken.refresh_token,
      rawToken.type,
      rawToken.data,
    )

    if (expireAt) {
      const expireDate = new Date().setTime(expireAt)
      token.expiresIn(expireDate)
    }

    return token
  }

  write(token: typeof Token) {
    const rawToken = {
      access: token.accessToken,
      refresh_token: token.refreshToken,
      type: token.tokenType,
      data: token.data,

      // Custom data to allow the persistance of the exact expiration date of the token
      expire_at: token.expires || null,
    }

    Cookie.set(this.cookieName, JSON.stringify(rawToken), {
      ...this._defaultCookieOptions(),
      expires: token.expires,
    })
  }

  delete() {
    Cookie.remove(this.cookieName, this._defaultCookieOptions())
  }

  _defaultCookieOptions() {
    return {
      secure: this.isSecureTransaction,
      path: '/',
    }
  }
}
