// @flow

import { action, observable, runInAction } from 'mobx'
import { RootStore } from '../RootStore'
import Program from '../Domain/Program'
import Building from '../Domain/Building'

export default class ProgramsStore {
  +rootStore: RootStore

  @observable programs: Program[] = []
  @observable prospects: {
    programName: string,
    href: string,
    delta: number,
    total: number,
    status: string,
  }[] = []

  @observable history: ?{
    programId: string,
    programName: string,
    events: Object,
  } = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action async fetchPrograms() {
    const response = await this.rootStore.authenticationStore.httpClient.get(
      '/api/seller/programs',
    )

    runInAction(() => {
      this.programs = response.data.map(
        oneProgramPayload =>
          new Program(
            oneProgramPayload.programId,
            oneProgramPayload.label,
            oneProgramPayload.location,
            oneProgramPayload.totalNumberOfLots,
            oneProgramPayload.totalNumberOfParkings,
            oneProgramPayload.pack,
            oneProgramPayload.domain,
            oneProgramPayload.buildings.map(
              oneBuildingPayload =>
                new Building(
                  oneBuildingPayload.buildingId,
                  oneBuildingPayload.label,
                  [],
                ),
            ),
            oneProgramPayload.baseVAT,
            oneProgramPayload.status,
            oneProgramPayload.lotsSalesStatus,
            oneProgramPayload.vatAvailable,
          ),
      )
    })
  }

  @action async fetchProspects() {
    const response = await this.rootStore.authenticationStore.httpClient.get(
      '/api/seller/download-links',
    )

    runInAction(() => {
      this.prospects = response.data
    })
  }

  @action async fetchHistory(programId: string, programName: string) {
    const response = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${programId}/history`,
    )

    const eventTypes = [
      'program_created_history',
      'program_price_updated_history',
      'program_lot_status_updated_history',
      'program_contact_request_history',
    ]
    const events = response.data.filter(oneEvent =>
      eventTypes.includes(oneEvent.type),
    )

    runInAction(() => {
      this.history = {
        programId: programId,
        programName: programName,
        events: events,
      }
    })
  }

  @action closeHistory() {
    this.history = null
  }

  @action async contactRequest(programId: string, contactRequestId: string) {
    await this.rootStore.authenticationStore.httpClient.put(
      `/api/seller/program/${programId}/contact_request/${contactRequestId}/contacted`,
    )
  }

  @action async getProspectXls(
    apiUrl: string,
    programName: string,
  ): Promise<string> {
    const response = await this.rootStore.authenticationStore.httpClient.get(
      apiUrl,
      { responseType: 'blob' },
    )

    runInAction(() => {
      const programProspect = this.prospects.find(
        oneProgramProspect => oneProgramProspect.programName === programName,
      )
      if (programProspect) {
        programProspect.delta = 0
      }
    })
    return response.data
  }

  getProgramsByStatus(
    status: string,
  ): {
    id: string,
    label: string,
    totalNumberOfLots: number,
    lotsSalesStatus: {
      unavailable: number,
      available: number,
      optioned: number,
      booked: number,
      sold: number,
    },
    status: string,
  }[] {
    return this.programs
      .filter(oneProgram => oneProgram.status === status)
      .map(oneProgram => ({
        id: oneProgram.id,
        label: oneProgram.label,
        totalNumberOfLots: oneProgram.totalNumberOfLots,
        lotsSalesStatus: oneProgram.lotsSalesStatus,
        status: oneProgram.status,
      }))
  }

  getProgram(programId: string): Program {
    const program = this.programs.find(
      oneProgram => oneProgram.id === programId,
    )
    if (!program) {
      throw new Error(`No program found with the id "${programId}"`)
    }

    return program
  }
}
