// @flow

import FilterDefinition, {
  type FilterValueComparator,
} from './FilterDefinition'
import FilterValues from './FilterValues'

export type FilterDescription = {|
  id: string,
  label: string,
  valueComparator: FilterValueComparator,
  choices: Array<{| value: any, label: string |}>,
  values: Array<any>,
|}

export default class FilterSet<T> {
  +filterDefinitions: FilterDefinition<T>[]
  +filterValues: FilterValues[]

  constructor(
    filterDefinitions: FilterDefinition<T>[],
    filterValues: FilterValues[],
  ) {
    this.filterDefinitions = filterDefinitions
    this.filterValues = filterValues

    Object.freeze(this)
  }

  static fromFilterDefinitions<T>(
    filterDefinitions: FilterDefinition<T>[],
  ): FilterSet<T> {
    return new FilterSet(
      filterDefinitions,
      filterDefinitions.map(
        oneFilterDefinition =>
          new FilterValues(
            oneFilterDefinition.id,
            oneFilterDefinition.comparator,
          ),
      ),
    )
  }

  toogleFilterValue(filterId: string, value: any): FilterSet<T> {
    const currentFilterValues = this.filterValues.find(
      oneFilterValues => oneFilterValues.id === filterId,
    )
    if (!currentFilterValues) {
      throw new Error(`Could not found the filter values "${filterId}"`)
    }

    currentFilterValues.toogleValue(value)

    return new FilterSet([...this.filterDefinitions], [...this.filterValues])
  }

  countActiveFilters(): number {
    return this.filterValues.filter(oneFilterValues =>
      oneFilterValues.haveValues(),
    ).length
  }

  atLeastOneFilterIsActive(): boolean {
    return this.filterValues.some(oneFilterValues =>
      oneFilterValues.haveValues(),
    )
  }

  filter(dataset: T[]): Array<T> {
    let newDataset = [...dataset]

    this.filterValues.forEach(oneFilterValues => {
      const currentFilterDefinition = this.filterDefinitions.find(
        oneFilter => oneFilter.id === oneFilterValues.id,
      )

      if (!currentFilterDefinition) {
        throw new Error(
          `Could found the filter definition of the filter values "${oneFilterValues.id}"`,
        )
      }

      if (oneFilterValues.values.length === 0) {
        return
      }

      newDataset = newDataset.filter(lot =>
        oneFilterValues.values.some(oneValue =>
          currentFilterDefinition.match(lot, oneValue),
        ),
      )
    })

    return newDataset
  }

  describe(): Array<FilterDescription> {
    return this.filterDefinitions.map(oneFilterDefinition => {
      const currentFilterValues = this.filterValues.find(
        oneCurrentFilter => oneCurrentFilter.id === oneFilterDefinition.id,
      )
      if (!currentFilterValues) {
        throw new Error(
          `Could found the filter values of the filter definition "${oneFilterDefinition.id}"`,
        )
      }

      return {
        id: oneFilterDefinition.id,
        label: oneFilterDefinition.label,
        valueComparator: oneFilterDefinition.comparator,
        choices: oneFilterDefinition.constraints.map(oneFilterConstraint => ({
          value: oneFilterConstraint.value,
          label: oneFilterConstraint.label,
        })),
        values: [...currentFilterValues.values],
      }
    })
  }
}
