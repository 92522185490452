// @flow

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getStatusColor } from '../../components/Helper'

const Dot = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${({ color }) => color};
  transition: background-color 0.15s ease-in-out;
`

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  ${Dot} {
    margin: 3px 7px 3px 3px;
  }
`

export const StatusDot = ({ value }: { value: string }) => {
  return <Dot color={getStatusColor(value)} />
}

export const LegendStatus = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <LegendItem>
        <Dot color={getStatusColor('unavailable')} />
        {t('status.unavailable')}
      </LegendItem>
      <LegendItem>
        <Dot color={getStatusColor('available')} />
        {t('status.available')}
      </LegendItem>
      <LegendItem>
        <Dot color={getStatusColor('optioned')} />
        {t('status.optioned')}
      </LegendItem>
      <LegendItem>
        <Dot color={getStatusColor('booked')} />
        {t('status.booked')}
      </LegendItem>
      <LegendItem>
        <Dot color={getStatusColor('sold')} />
        {t('status.sold')}
      </LegendItem>
    </React.Fragment>
  )
}
